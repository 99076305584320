define("ember-user-activity/services/user-idle", ["exports", "ember", "ember-user-activity/services/-private/event-manager", "@ember/service", "@ember/runloop", "@glimmer/tracking"], function (_exports, _ember, _eventManager, _service, _runloop, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserIdleService = _exports.default = (_dec = (0, _service.inject)('ember-user-activity@user-activity'), _class = class UserIdleService extends _eventManager.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userActivity", _descriptor, this);
      _defineProperty(this, "_debouncedTimeout", null);
      _defineProperty(this, "activeEvents", ['userActive']);
      _defineProperty(this, "IDLE_TIMEOUT", 600000);
      // 10 minutes
      _initializerDefineProperty(this, "isIdle", _descriptor2, this);
    }
    _setupListeners(method) {
      this.activeEvents.forEach(event => {
        this.userActivity[method](event, this, this.resetTimeout);
      });
    }

    // TODO: migrate to constructor
    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      super.init(...arguments);
      if (_ember.default.testing) {
        // Shorter debounce in testing mode
        this.IDLE_TIMEOUT = 10;
      }
      this._setupListeners('on');
      this.resetTimeout();
    }
    willDestroy() {
      this._setupListeners('off');
      if (this._debouncedTimeout) {
        (0, _runloop.cancel)(this._debouncedTimeout);
      }
      super.willDestroy(...arguments);
    }
    resetTimeout() {
      let oldIdle = this.isIdle;
      this.isIdle = false;
      if (oldIdle) {
        this.trigger('idleChanged', false);
      }
      this._debouncedTimeout = (0, _runloop.debounce)(this, this.setIdle, this.IDLE_TIMEOUT);
    }
    setIdle() {
      if (this.isDestroyed) {
        return;
      }
      this.isIdle = true;
      this.trigger('idleChanged', true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "userActivity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isIdle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class);
});