define("ember-cli-bugsnag/utils/bugsnag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMetadata = getMetadata;
  _exports.getUser = getUser;
  function getMetadata(/* error, container */
  ) {
    return {};
  }
  function getUser(/* container */
  ) {
    return {};
  }
});