define("ember-credit-cards/components/input-credit-card-expiration", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ember-credit-cards/utils/formatters", "ember-credit-cards/utils/has-text-selected", "ember-credit-cards/utils/is-digit-keypress"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _formatters, _hasTextSelected, _isDigitKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    @type="tel"
    @value={{this.value}}
    autocomplete="cc-exp"
    class="input-credit-card-expiration"
    placeholder="•• / ••"
    required="true"
    ...attributes
    {{on "keypress" this.keyPress}}
  />
  
  */
  {
    "id": "Bu8HulEj",
    "block": "[[[8,[39,0],[[24,\"autocomplete\",\"cc-exp\"],[24,0,\"input-credit-card-expiration\"],[24,\"placeholder\",\"•• / ••\"],[24,\"required\",\"true\"],[17,1],[4,[38,1],[\"keypress\",[30,0,[\"keyPress\"]]],null]],[[\"@type\",\"@value\"],[\"tel\",[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[\"&attrs\"],false,[\"input\",\"on\"]]",
    "moduleName": "ember-credit-cards/components/input-credit-card-expiration.hbs",
    "isStrictMode": false
  });
  function inputValid(value) {
    if (!value) {
      return false;
    }
    value = (value + '').replace(/\D/g, '');
    if (value.length > 6) {
      return false;
    }
    return true;
  }
  function parseInput(value) {
    var parts = (value + '').match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);
    if (!parts) {
      return [];
    }
    var mon = parts[1] || '';
    var year = parts[3] || '';
    if (mon.length === 1 && mon !== '0' && mon !== '1') {
      mon = '0' + mon;
    }
    return [mon, year];
  }
  let InputCreditCardExpirationComponent = _exports.default = (_class = class InputCreditCardExpirationComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_value", _descriptor, this);
      this._value = _formatters.default.formatExpiration(this.args.month, this.args.year);
    }
    get value() {
      return this._value;
    }
    set value(value) {
      var isBackspace = this._value.length - 1 === value.length;
      var parsed = parseInput(value);
      var month = parsed[0];
      var year = parsed[1];
      this._value = _formatters.default.formatExpiration(month, year, isBackspace);
      this.args.onUpdateMonth(month);
      this.args.onUpdateYear(year);
    }
    keyPress(e) {
      var digit = String.fromCharCode(e.which);
      if (!(0, _isDigitKeypress.default)(e)) {
        return false;
      }
      if ((0, _hasTextSelected.default)(e.target)) {
        return true;
      }
      var value = this._value + digit;
      return inputValid(value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keyPress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "keyPress"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputCreditCardExpirationComponent);
});