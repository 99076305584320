define("ember-credit-cards/components/input-credit-card-cvc", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-credit-cards/utils/has-text-selected", "ember-credit-cards/utils/formatters", "ember-credit-cards/utils/is-digit-keypress", "ember-credit-cards/utils/is-whitelist-keypress"], function (_exports, _component, _templateFactory, _component2, _object, _hasTextSelected, _formatters, _isDigitKeypress, _isWhitelistKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    @type="tel"
    @value={{this.cvc}}
    class="input-credit-card-cvc"
    placeholder="•••"
    ...attributes
    {{on "keypress" this.keyPress}}
  />
  
  */
  {
    "id": "qJN1/ozq",
    "block": "[[[8,[39,0],[[24,0,\"input-credit-card-cvc\"],[24,\"placeholder\",\"•••\"],[17,1],[4,[38,1],[\"keypress\",[30,0,[\"keyPress\"]]],null]],[[\"@type\",\"@value\"],[\"tel\",[30,0,[\"cvc\"]]]],null],[1,\"\\n\"]],[\"&attrs\"],false,[\"input\",\"on\"]]",
    "moduleName": "ember-credit-cards/components/input-credit-card-cvc.hbs",
    "isStrictMode": false
  });
  function inputValid(value) {
    return value.length <= 4;
  }
  let InputCreditCardCvcComponent = _exports.default = (_class = class InputCreditCardCvcComponent extends _component2.default {
    get cvc() {
      return _formatters.default.formatCvc(this.args.cvc);
    }
    set cvc(value) {
      this.args.onUpdate(value);
    }
    keyPress(e) {
      var digit = String.fromCharCode(e.which);
      if (!(0, _isDigitKeypress.default)(e)) {
        return false;
      }
      if ((0, _hasTextSelected.default)(e.target)) {
        return true;
      }
      var value = (this.args.cvc || '') + digit;
      return (0, _isWhitelistKeypress.default)(e) || inputValid(value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "keyPress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "keyPress"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputCreditCardCvcComponent);
});